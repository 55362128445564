import { createTheme } from '@mui/system'
import { createTheme as createMuiTheme } from '@mui/material/styles' // keep @mui/material/styles to use default theme of @mui/material
import defaultTheme from '@bluheadless/ui/src/theme/default'
import { fonts } from './theme/fonts'
import Input from './theme/components/form/input'
import BHInput from './theme/components/form/bh-input'
import CssBaseline from './theme/components/css-baseline'
import Button from './theme/components/button'
import Form from './theme/components/form/form'
import Dialog from './theme/components/dialog'
import Checkbox from './theme/components/form/checkbox'
import BHSuggestedStoreRow from './theme/components/bh-suggested-store-row'
import BHHeader from './theme/components/bh-header'
import BHTileBase from './theme/components/bh-tile-base'
import SvgIcon from './theme/components/svg-icon'
import Tooltip from './theme/components/tooltip'
import Link from './theme/components/link'
import List from './theme/components/list'
import DatePicker from './theme/components/form/date-picker'
import BHBreadcrumbs from './theme/components/bh-breadcrumbs'
import Accordion from './theme/components/accordion'
import Alert from './theme/components/alert'
import BHRelated from './theme/components/bh-related'
import BHUpsells from './theme/components/bh-upsells'
import BHSearch from './theme/components/bh-search'
import BHQuickBuy from './theme/components/bh-quick-buy'
import BHTileTooltip from './theme/components/bh-tile-tooltip'
import Table from './theme/components/table'
import BHRecentlyViewed from './theme/components/bh-recently-viewed'

const htmlFontSize = 0.75
const fontSize = 0.75

const muiTheme = createMuiTheme({ typography: { htmlFontSize, fontSize } })

const fontFamily = fonts.gothic.fontFamily
const fontFamilyAlternative = fonts.gothic.fontFamily

export const theme = createTheme(defaultTheme, {
	palette: {
		common: {
			black: '#000000',
			white: '#FFFFFF',
			red: '#D00A26',
		},
		black: {
			main: '#000000',
		},
		primary: {
			main: '#000000',
			light: '#333333',
			dark: '#000000',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#ffffff',
			light: '#fcfcfc',
			dark: '#fcfcfc',
			contrastText: '#000000',
		},
		grey: {
			main: '#747474',
			input: '#676767',
			border: '#EAEAEA',
			label: '#AFAFAF',
			mosaic: '#F3F3F3',
		},
		footer: {
			bgTop: '#FFFFFF',
			bgMiddleTop: '#FFFFFF',
			bgMiddle: '#FFFFFF',
			bgMiddleBottom: '#FFFFFF',
			bgBottom: '#FFFFFF',
			border: '#3636367F',
			color: '#000000',
			copyright: '#777777',
			service: '#F2F2F2',
		},
		background: {
			paper: '#FFFFFF',
			default: '#FFFFFF',
			header: '#FFFFFF',
			card: '#F6F6F6',
		},
		tile: {
			background: '#F6F6F6',
		},
		button: {
			disabled: '#A8A8A8',
		},
	},
	typography: {
		color: '#000000',
		fontFamily,
		fontFamilyAlternative,
		htmlFontSize,
		fontSize,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		h1: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(30),
			lineHeight: 'normal',
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
			},
		},
		h2: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: 'normal',
			letterSpacing: '0px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
			},
		},
		h3: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(20),
			lineHeight: 'normal',
			letterSpacing: '0px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(18),
			},
		},
		h4: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(18),
			lineHeight: 'normal',
			letterSpacing: '0px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(22),
			},
		},
		h5: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: 'normal',
			letterSpacing: '0px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(20),
			},
		},
		h6: {
			...fonts.gothic,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 'normal',
			letterSpacing: '0px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(16),
			},
		},
		body0: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(10),
			lineHeight: 'normal',
			letterSpacing: '0',
		},
		body1: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 1.2,
			letterSpacing: '0',
		},
		body2: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.2,
			letterSpacing: '0',
		},
		subtitle1: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.2,
			letterSpacing: '0',
		},
		subheadline1: {
			...fonts.gothic,
		},
		subheadline2: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.2,
			letterSpacing: '0',
		},
		button: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.2,
			letterSpacing: '0',
		},
		caption: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 1.2,
			letterSpacing: '0',
		},
		overline: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 1.2,
			letterSpacing: '0',
			textTransform: 'uppercase',
		},
		card: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.2,
			letterSpacing: '0',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.sm}px)`]: {
				fontSize: muiTheme.typography.pxToRem(24),
			},
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
			},
		},
		attribute: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(12),
			fontWeight: '600',
			paddingBottom: '2px',
		},
		selectedOption: {
			...fonts.gothic,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 'normal',
			letterSpacing: '0',
			fontWeight: '600',
		},
	},
	shape: {
		borderRadius: 0,
	},
	components: {
		...Accordion,
		...Alert,
		...CssBaseline,
		...Input,
		...BHInput,
		...Button,
		...Form,
		...Dialog,
		...Checkbox,
		...BHSuggestedStoreRow,
		...BHHeader,
		...SvgIcon,
		...BHTileBase,
		...Tooltip,
		...Link,
		...List,
		...DatePicker,
		...BHBreadcrumbs,
		...BHQuickBuy,
		...BHRelated,
		...BHUpsells,
		...BHSearch,
		...BHTileTooltip,
		...Table,
		...BHRecentlyViewed,
	},
})

export default theme
